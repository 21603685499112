.rte-root .ck {
  font-family: "Prompt", sans-serif !important;
  font-size: 18px;
}
.rte-root
  .ck.ck-content.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  outline: none;
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.editor-on html,
.editor-on body {
  overflow: hidden;
}
